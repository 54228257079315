import axios from "axios";
import { BASE_URL, ECHO_SERVICE_URI, SUBSCRIPTION_BASEURL } from "../config";
import DBStorage from "./DBStorage";
import { toast } from "react-toastify";

const USER_API_URL = 'login/me'

const refreshToken = () => {
  return new Promise((resolve, reject) => {
    const token = DBStorage.get('token', false)    
    axios({
      url: `${BASE_URL}/api/${USER_API_URL}`,
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': token ? `Bearer ${token}` : ''
      },
      withCredentials: true
    })
      .then(response => {
        DBStorage.set('token',response.data?.data?.token)
        resolve(response.data?.data?.token) 
      })
      .catch(e => {
        reject(e)
      })
  })
}

export const axiosAPIInterface = axios.create()

axiosAPIInterface.interceptors.response.use((response => {	
	return response
}), async (error) => {	
	const originalRequest = error.config  
	if(error.response.status === 401 && !originalRequest._retry){
		originalRequest._retry = true 
		try{
			const access_token = await refreshToken();			
      originalRequest.headers['Authorization'] = `Bearer ${access_token}`
			return axiosAPIInterface(originalRequest) 
		}
		catch(e){			
			throw e
		}
	}
  else{    
    if(error.response.status === 502){
      throw new Error(error.response.statusText)
    }
    throw error
  }
})

export const post = async (apiURL: string, data: any) => {
  let token = DBStorage.get("token", false);
  return axiosAPIInterface.post(`${BASE_URL}/api/${apiURL}`, data, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const uploadfilePost = async (apiURL: string, data: any) => {
  return axiosAPIInterface.post(`${BASE_URL}/api/${apiURL}`, data, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: 'letzchange-receipts',
    },
  });
};
export const donationPost = async (apiURL: string, data: any) => {
  let token = DBStorage.get("token", false);
  return axiosAPIInterface.post(`${BASE_URL}/api/${apiURL}`, data, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Accept": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

// CANCEL APIS
export const subscriptionPaused = async (apiURL: string, data: any,subscriptionId:any) => {
  let token = DBStorage.get("token", false);
  return axiosAPIInterface.put(`${SUBSCRIPTION_BASEURL}/${subscriptionId}/${apiURL}`, data, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Accept": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const subscriptionCancelled = async (apiURL: string, data: any,subscriptionId:any) => {
  let token = DBStorage.get("token", false);
  return axiosAPIInterface.put(`${SUBSCRIPTION_BASEURL}/${subscriptionId}/${apiURL}`, data, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Accept": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const subscriptionAmountUpdate = async (apiURL: string, data: any, subscriptionId:any) => {
  let token = DBStorage.get("token", false);
  return axiosAPIInterface.put(`${SUBSCRIPTION_BASEURL}/${subscriptionId}/${apiURL}`, data, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Accept": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const subscriptionResume = async (apiURL: string, data: any,subscriptionId:any) => {
  let token = DBStorage.get("token", false);
  return axiosAPIInterface.put(`${SUBSCRIPTION_BASEURL}/${subscriptionId}/${apiURL}`, data, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Accept": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

// 

export const noAuthPost = async (apiURL: string, data: any) => {
  return axiosAPIInterface.post(`${BASE_URL}/api/${apiURL}`, data, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
};

export const get = async (apiURL: string, params?: any) => {
  let token = DBStorage.get("token", false);
  return axiosAPIInterface.get(`${BASE_URL}/api/${apiURL}`, {
    params,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: token,
    },
    withCredentials: true,
  });
};

export const thirdPartyAPI = async (
  apiURL: string,
  method: string = "GET",
  data: any = null
) => {
  if (method === "PUT") {
    let token = DBStorage.get("token", false);
    return axiosAPIInterface.put(apiURL, data, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: `Bearer ${token}`,
      },
    });
  } else if (method === "DELETE") {
    let token = DBStorage.get("token", false);
    return axiosAPIInterface.delete(apiURL, {
      params: data || {},
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: token,
      },
    });

  } else {
    return axios.get(apiURL);
  }
};

export const noAuthGet = async (apiURL: string, params?: any) => {
  return axiosAPIInterface.get(`${BASE_URL}/api/${apiURL}`, {
    params,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
};

export const put = async (apiURL: string, data: any) => {
  let token = DBStorage.get("token", false);
  return axiosAPIInterface.put(`${BASE_URL}/api/${apiURL}`, data, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: token,
    },
  });
};

export const del = async (apiURL: string, params: any) => {
  let token = DBStorage.get("token", false);
  return axiosAPIInterface.delete(`${BASE_URL}/api/${apiURL}`, {
    params,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: token,
    },
  });
};

export const getSearch = async (apiURL: string, params?: any) => {
  let token = DBStorage.get("token", false);
  return axiosAPIInterface.get(`${BASE_URL}/${apiURL}`, {
    params,
    withCredentials: false, // added this so that all cookies are not sent in the request
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: token,
    },
  });
};

export const validateEmailOrPhone = async(data:any) => {
  return noAuthPost(`emails/validate/`,data) 
}

export const insertReminder = async (apiURL: string, data?: any) => {
  let token = DBStorage.get("token", false);
  return axiosAPIInterface.post(`${BASE_URL}/api/${apiURL}`, data, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: token,
    },
  });
};

export const getReminder = async (apiURL: string) => {
  let token = DBStorage.get("token", false);
  return axiosAPIInterface.get(`${BASE_URL}/api/${apiURL}`, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: token,
    },
  });
};

export const deleteReminder = async (apiURL: string,id:string) => {
  let token = DBStorage.get("token", false);
  return axiosAPIInterface.delete(`${BASE_URL}/api/${apiURL}/${id}`, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: token,
    },
  });
};

export const downnloadFile = async ( file_id: string,file_type?:string) => {
  return axiosAPIInterface.get(`${BASE_URL}/api/files/downloadfile?fileLink=${file_id}`)
}

export const downloadPdf=(fileUrl: string,financialYear?:string)=>{
  const url = fileUrl;
  fetch(
    `${BASE_URL}/api/files/downloadfile?fileLink=${url}`,
    {
      method: 'get',
      headers: {
        'Content-Type': 'application/pdf',
        'Accept': 'application/json',
        "authorization": "letzchange-receipts"
      },
    })
    .then(response => response.blob())
    .then(blobData => {
      if (blobData.size > 0) {
      const url = window.URL.createObjectURL(new Blob([blobData]));
      const link = document.createElement('a');
      link.href = url;
      const checkFileUrl = fileUrl?.includes('webp') 
        ? 'file.webp' 
          : (fileUrl.includes('.zip') 
        ? `${financialYear || 'file'}.zip` 
          : 'file.pdf');
      link.setAttribute('download', checkFileUrl);
      document.body.appendChild(link);
      link.click();}
      else{
        toast.error('File not found. Please try again in sometime', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });
      }
    })
    .catch(error => {
      toast.error('Error fetching PDF:', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
      console.error('Error fetching PDF:', error);
    });
}
export const downloadZip=(fileUrl: string,name:string)=>{
  const url = fileUrl;
  fetch(
    `${BASE_URL}/api/files/downloadfile?fileLink=${url}`,
    {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        "authorization": "letzchange-receipts"
      },
    })
    .then(response => response.blob())
    .then(blobData => {
      if (blobData.size > 0) {
      const url = window.URL.createObjectURL(new Blob([blobData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${name}_giveindia.zip`);
      document.body.appendChild(link);
      link.click();}
      else{
        toast.error('File not found. Please try again in sometime', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });
      }
    })
    .catch(error => {
      toast.error('Error fetching PDF:', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
      console.error('Error fetching PDF:', error);
    });
}   


